var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panels",
    { attrs: { flat: "" } },
    [
      _c(
        "v-expansion-panel",
        [
          _c("v-expansion-panel-header", { staticClass: "pa-0" }, [
            _vm._v(" History ")
          ]),
          _c(
            "v-expansion-panel-content",
            [
              _c(
                "v-card",
                { staticClass: "ma-0 pa-0", attrs: { outlined: "" } },
                [
                  _c("v-data-table", {
                    attrs: {
                      height: _vm.tableSize,
                      headers: _vm.headers,
                      items: _vm.promoHistories,
                      dense: "",
                      "fixed-header": "",
                      "items-per-page": -1,
                      "footer-props": { "items-per-page-options": [-1] }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.notes",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              item.notes
                                ? _c(
                                    "div",
                                    {
                                      style:
                                        item.notes.length >= 100
                                          ? "width: 250px"
                                          : null
                                    },
                                    [_vm._v(" " + _vm._s(item.notes) + " ")]
                                  )
                                : _c("span", [_vm._v("-")])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }