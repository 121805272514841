import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class WorkflowStatus {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.workflowStatus = base
  }

  async getWorkflow (entityTypeId) {
    await AuthToken.setAuth(this.workflowStatus)

    return this.workflowStatus({
      url: `/status/workflow/${entityTypeId}`,
      method: 'GET'
    })
  }

  async getWorkflowValues (workflowId) {
    await AuthToken.setAuth(this.workflowStatus)

    return this.workflowStatus({
      url: `/status/workflow_values/${workflowId}`,
      method: 'GET'
    })
  }

  async getStatus (entity_id) {
    await AuthToken.setAuth(this.workflowStatus)

    return this.workflowStatus({
      url: `/statuses/${entity_id}`,
      method: 'GET'
    })
  }

  async add (payload) {
    await AuthToken.setAuth(this.workflowStatus)

    return this.workflowStatus({
      url: `/status`,
      method: 'POST',
      data: payload
    })
  }
}

export default new WorkflowStatus()