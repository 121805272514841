<template>
  <v-expansion-panels flat>
    <v-expansion-panel>
      <v-expansion-panel-header class="pa-0">
        History
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card outlined class="ma-0 pa-0">
          <v-data-table
            :height="tableSize"
            :headers="headers"
            :items="promoHistories"
            dense
            fixed-header
            :items-per-page="-1"
            :footer-props="{'items-per-page-options': [-1]}">
            <template v-slot:[`item.notes`]="{ item }">
              <div 
                v-if="item.notes" 
                :style="(item.notes.length >= 100) ? 'width: 250px' : null">
                {{ item.notes }}
              </div>
              <span v-else>-</span>
            </template>
          </v-data-table>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { fullWidthTable } from "@/mixins/table"
import WorkflowStatus from "@/axios/status-endpoints"
import { displayAlert } from "@/mixins/alert"
export default {
  name: "statusHistory",
  props: {
    promo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    promoHistories: [],
    headers: [
      {
        text: "Status",
        value: "statusName",
        class: "accent white--text",
        width: '120'
      },
      {
        text: "Notes",
        value: "notes",
        class: "accent white--text",
        sortable: false
      },
      {
        text: "Created On",
        value: "createdOn",
        class: "accent white--text",
        width: '120'
      },
      {
        text: "Created By",
        value: "createdBy",
        class: "accent white--text"
      }
    ],
  }),
  mixins: [displayAlert, fullWidthTable],
  created() {
    this.nonTableHeight = 550
    this.getStatusHistory()
  },
  methods: {
    async getStatusHistory() {
      try {
        this.promoHistories = (
          await WorkflowStatus.getStatus(this.promo.id)
        ).data.map((e) => {
          return {
            createdOn: new Date(e.audit.created_on).toLocaleDateString(),
            notes: e.notes,
            statusName: e.status.name,
            createdBy: e.audit.created_by
          }
        })
      } catch (err) {
        this.handleError(err)
      }
    },
  },
}
</script>
<style scoped>
div.v-expansion-panels >>> div.v-expansion-panel-content__wrap {
  padding: 0 0 0 0 !important;
}
</style>