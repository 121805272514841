var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, "max-width": "610", persistent: "" } },
    [
      _c(
        "v-snackbar",
        {
          attrs: {
            top: "",
            "content-class": "pa-0",
            color: "warning",
            timeout: -1
          },
          model: {
            value: _vm.missingLinkedItemsAlert,
            callback: function($$v) {
              _vm.missingLinkedItemsAlert = $$v
            },
            expression: "missingLinkedItemsAlert"
          }
        },
        [
          _c(
            "v-alert",
            {
              staticClass: "my-0",
              attrs: {
                dense: "",
                border: "left",
                prominent: "",
                type: "warning",
                "retain-focus": true
              },
              model: {
                value: _vm.missingLinkedItemsAlert,
                callback: function($$v) {
                  _vm.missingLinkedItemsAlert = $$v
                },
                expression: "missingLinkedItemsAlert"
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.missingLinkedItemsMessage) + " "),
              _c("br"),
              _vm._v(" Submit promotion with missing linked items? "),
              _c(
                "span",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-4",
                      attrs: { outlined: "", loading: _vm.saving },
                      on: { click: _vm.submitWithMissingLinkedItems }
                    },
                    [_vm._v(" Yes ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          _vm.missingLinkedItemsAlert = false
                        }
                      }
                    },
                    [_vm._v(" No ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _vm._v("Update Promotion Status "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "grey darken-2" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.saving,
                    disabled: _vm.changesDisabled,
                    color: "primary"
                  },
                  on: { click: _vm.saveStatus }
                },
                [_vm._v(" Save ")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "mb-0 pb-0 px-4" },
            [
              _c(
                "v-container",
                { staticClass: "mt-0 pt-0" },
                [
                  _c(
                    "v-row",
                    [
                      _vm.promoStatus
                        ? _c(
                            "v-col",
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Status",
                                  "menu-props": { offsetY: true },
                                  items: _vm.validStatuses,
                                  "item-text": "name",
                                  "return-object": "",
                                  "hide-details": "auto",
                                  disabled: _vm.changesDisabled,
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.promoStatus.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.promoStatus, "status", $$v)
                                  },
                                  expression: "promoStatus.status"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Notes",
                              disabled: _vm.changesDisabled,
                              outlined: "",
                              dense: "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.promoStatus.notes,
                              callback: function($$v) {
                                _vm.$set(_vm.promoStatus, "notes", $$v)
                              },
                              expression: "promoStatus.notes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("StatusHistory", { attrs: { promo: _vm.promo } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }